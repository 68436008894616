<template>
  <v-container>
    <v-data-iterator
      :items="items"
      item-key="title"
      hide-default-footer
      :search="search"
    >
      <template v-slot:default="{ items }">
        <v-row>
          <v-col
            v-for="item in items"
            :key="item.title"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title>
                <h4>{{ item.title }}</h4>
              </v-card-title>
              <v-card-subtitle>
                {{ item.subtitle }}
              </v-card-subtitle>
              <v-card-text>{{ item.description }}</v-card-text>
              <v-card-actions v-if="item.link">
                <v-btn color="secondary" text :href="item.link" target="_blank">
                  Link <v-icon right>mdi-open-in-new</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
export default {
  name: 'DocumentationTools',

  props: ['search'],
  data() {
    return {
      items: [
        {
          title: 'Vuetify',
          subtitle: 'UI Library',
          description:
            'Vuetify is a Vue UI Library with beautifully handcrafted Material Components. No design skills required — everything you need to create amazing applications is at your fingertips.',
          link: 'https://vuetifyjs.com/en/introduction/why-vuetify/#guide',
        },
        {
          title: 'Vue 2',
          subtitle: 'js-Framework',
          description:
            'Vue (pronounced /vjuː/, like view) is a progressive framework for building user interfaces.',
          link: 'https://vuejs.org/v2/guide/',
        },
        {
          title: 'Msal Browser',
          subtitle: 'Autentifizierung',
          description: 'OAuth2-Authentifizierung über Microsoft',
          link: 'https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-browser',
        },
        {
          title: 'Material Design Icons',
          subtitle: 'Icons',
          description: 'erweiterte Google Icons',
          link: 'https://materialdesignicons.com',
        },
      ],
    };
  },
};
</script>